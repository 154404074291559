<template>
    <CSidebar
        position="fixed"
        :unfoldable="sidebarUnfoldable"
        :visible="sidebarVisible"
        @visible-change="updateSidebarVisible"
    >
        <CSidebarBrand>
            <h5 style="cursor: pointer">
                <img :src="imgLogo" width="35" alt="Apotek Himawan" />
                Himawan
            </h5>
            <i class="ms-1">Farma</i>
        </CSidebarBrand>
        <AppSidebarNav />
        <CSidebarToggler class="d-none d-lg-flex" @click="toggleUnfoldable" />
    </CSidebar>
</template>

<script>
import { useSidebarStore } from '@/store/index.js'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import { mapActions, mapState } from 'pinia'
import imgLogo from '@/assets/images/logo.jpeg'
export default {
    name: 'AppSidebar',
    components: {
        AppSidebarNav,
    },
    data() {
        return {
            logoNegative,
            sygnet,
            imgLogo,
        }
    },
    computed: {
        ...mapState(useSidebarStore, ['sidebarUnfoldable', 'sidebarVisible']),
    },
    methods: {
        ...mapActions(useSidebarStore, [
            'updateSidebarVisible',
            'toggleUnfoldable',
        ]),
    },
}
</script>
